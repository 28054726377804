import { useState } from 'react';

export const useSubmenu = () => {
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const toggleSubmenu = (label) => {
        setActiveSubmenu(activeSubmenu === label ? null : label);
    };

    return { activeSubmenu, toggleSubmenu };
};
