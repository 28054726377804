import React, { useEffect, useRef } from 'react'
import Macy from "macy"

import PaddingGlobal from "../../layout/PaddingGlobal"
import SiteContainer from "../../layout/Container"

const MacyMasonry = () => {
    const masonryRef = useRef(null);

    const cards = [
        {
            name: "Adi",
            imgSrc: 'https://via.placeholder.com/500x647.png'
        },
        {
            name: "Amie",
            imgSrc: 'https://via.placeholder.com/500x647.png'
        },
        {
            name: "Axton",
            imgSrc: 'https://via.placeholder.com/500x409.png'
        },
        {
            name: "Beatae",
            imgSrc: 'https://via.placeholder.com/500x750.png'
        },
        {
            name: "Calex",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Draven",
            imgSrc: 'https://via.placeholder.com/500x667.png'
        },
        {
            name: "Jorvik",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Loxley",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Maelie",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Quinly",
            imgSrc: 'https://via.placeholder.com/500x750.png'
        },
        {
            name: "Serin",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Tindra",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Totam",
            imgSrc: 'https://via.placeholder.com/500x750.png'
        },
        {
            name: "Ullam",
            imgSrc: 'https://via.placeholder.com/500x750.png'
        },
        {
            name: "Zaela",
            imgSrc: 'https://via.placeholder.com/500x333.png'
        },
        {
            name: "Zephyra",
            imgSrc: 'https://via.placeholder.com/500x332.png'
        }
    ];

    useEffect(() => {
        if (masonryRef.current) {
            new Macy({
                container: masonryRef.current,
                margin: 24,
                columns: 3,
                breakAt: {
                    767: 2,
                    479: 1
                }
            });

            // Menghapus kelas "is-loading"
            const items = masonryRef.current.querySelectorAll('.masonry__card');
            items.forEach(item => item.classList.remove('is-loading'));
        }
    }, []); // Kosongkan array dependensi agar efek hanya di-run sekali saat komponen dipasang


    return (
        <div className="py-[120px] w-full">
            <PaddingGlobal>
                <SiteContainer>
                    
                    {/* Wrapper */}
                    <div ref={masonryRef} className="relative flex flex-row flex-wrap -mb-8 max-1023:-mb-6">

                        {cards.map((item, index) => (
                            <div key={index} className="relative flex flex-row flex-wrap mb-8 max-1023:mb-6 rounded-lg overflow-hidden masonry__card">
                                <div className="relative">
                                    <img src={item.imgSrc} alt={item.name} className='w-full h-full object-cover' />

                                    <span className='inline-flex top-0 right-0 absolute bg-blue-100 px-4 py-2 font-medium text-blue-600 text-xs uppercase'>{item.name}</span>
                                </div>
                            </div>
                        ))}

                    </div>

                </SiteContainer>
            </PaddingGlobal>
        </div>
    )
}

export default MacyMasonry
