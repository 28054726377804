export const navItems = [
    { label: "Home", href: "#" },
    {
        label: "Pages",
        href: "#",
        submenu: [
            { label: "About Me", href: "#" },
            { label: "About Us", href: "#" },
            { label: "Our Services", href: "#" },
            { label: "Contact Us", href: "#" },
            { label: "FAQ Pages", href: "#" },
            { label: "404 Error Page", href: "#" },
            { label: "Maintenance Page", href: "#" },
            { label: "Coming Soon", href: "#" },
        ],
    },
    {
        label: "Portfolio",
        href: "#",
        submenu: [
            { label: "Single Portfolio", href: "#" },
            { label: "Fullwidth Portfolio", href: "#" },
            { label: "Normal Portfolio", href: "#" },
        ],
    },
    { label: "Blog", href: "#" },
    { label: "Shop!", href: "#" },
];
