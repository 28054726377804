import React from 'react';
import { UseMax991 } from "../MediaQueries";
import NavigationDesktop from './components/NavigationDesktop';
import NavigationMobile from './components/NavigationMobile';
import Cart from './components/Cart';
import Sidebar from './components/Sidebar';
import Logo from './components/Logo';
import { useToggle } from './hooks/useToggle';
import { useEscapeKey } from './hooks/useEscapeKey';

const HeaderOne = () => {
    const isMax991 = UseMax991();
    const [isSidebarOpen, toggleSidebar, setSidebarOpen] = useToggle(false);

    useEscapeKey(() => setSidebarOpen(false));

    return (
        <header className="flex justify-start items-center border-b border-b-gray-300 border-solid w-full h-32 max-991:h-24">
            <Logo />

            {!isMax991 ? (
                <>
                    <NavigationDesktop />
                    <Cart />
                    <Sidebar
                        isOpen={isSidebarOpen}
                        toggle={toggleSidebar}
                    />
                </>
            ) : (
                <NavigationMobile />
            )}
        </header>
    );
};

export default HeaderOne;
