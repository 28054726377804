import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import Flip from 'gsap/Flip';
import chroma from 'chroma-js';

// Layout
import PaddingGlobal from "../../layout/PaddingGlobal"
import SiteContainer from "../../layout/Container"


gsap.registerPlugin(Flip);

const MagneticTooltipGsap = () => {
    const followerTextRef = useRef(null); // Title
    const followerPopupRef = useRef(null); // Popupnya
    const followerListRef = useRef(null); // List wrapper
    const followerItemsRefs = useRef([]); // Item

    const items = [
        {
            color: '#186afe',
            name: 'Checkout',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb3e9152320c03bc01d71_Checkout%20400x400.jpeg'
        },
        {
            color: '#00d72e',
            name: 'Cash App',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb2fab39f230793873210_Cash%20App.png'
        },
        {
            color: '#ffb3c7',
            name: 'Klarna',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb2e8428fb42baf7329d2_Klarna%20512x512.png'
        },
        {
            color: '#002987',
            name: 'PayPal',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb2d53baedbbd9e060390_PayPal%20512x512.png'
        },
        {
            color: '#004022',
            name: 'Robinhood',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb2c1d1b580788b35077e_Robinhood%20512x512.png'
        },
        {
            color: '#635bff',
            name: 'Stripe',
            imgSrc: 'https://assets-global.website-files.com/662fb1cae734ec2faa0834f3/662fb2abd567801ade39b830_Stripe%20512x512.png'
        }
    ];

    useEffect(() => {
        const showPopup = () => {
            gsap.to(followerTextRef.current, { autoAlpha: 0 });
            gsap.to(followerPopupRef.current, { autoAlpha: 1 });
        };

        const hidePopup = () => {
            gsap.to(followerTextRef.current, { autoAlpha: 1 });
            gsap.to(followerPopupRef.current, { autoAlpha: 0 });
        };

        const onItemMouseEnter = (item, index) => {
            const state = Flip.getState(followerPopupRef.current, {
                props: "color, backgroundColor",
            });
            const color = item.getAttribute("magnetic-tooltip-gsap-color");
            const backgroundColor = chroma(color).desaturate(0.5).mix("white", 0.7);

            followerPopupRef.current.textContent = item.getAttribute("magnetic-tooltip-gsap-name");
            gsap.set(followerPopupRef.current, {
                color,
                backgroundColor,
            });

            if (index === 0) {
                gsap.set(followerPopupRef.current, {
                    left: '24px',
                    right: "auto",
                });
            } else if (index === items.length - 1) {
                gsap.set(followerPopupRef.current, {
                    left: "auto",
                    right: '24px',
                });
            } else {
                const adjustment = 24; // Adjust this value to fine-tune
                const centerX = item.offsetLeft + item.offsetWidth / 2 - followerPopupRef.current.offsetWidth / 2 + adjustment;
                gsap.set(followerPopupRef.current, { left: centerX, right: "auto" });
            }

            Flip.from(state, {
                duration: 0.3,
                ease: "power2.out",
            });
        };

        const listEl = followerListRef.current;
        if (listEl) {
            listEl.addEventListener('mouseenter', showPopup);
            listEl.addEventListener('mouseleave', hidePopup);
        }

        followerItemsRefs.current.forEach((item, index) => {
            if (item) {
                item.addEventListener('mouseenter', () => onItemMouseEnter(item, index));
            }
        });

        // Cleanup event listeners on component unmount
        return () => {
            if (listEl) {
                listEl.removeEventListener('mouseenter', showPopup);
                listEl.removeEventListener('mouseleave', hidePopup);
            }

            followerItemsRefs.current.forEach(item => {
                if (item) {
                    item.removeEventListener('mouseenter', () => onItemMouseEnter(item));
                }
            });
        };
    }, [items]);

    return (
        <div className="h-svh">
            <PaddingGlobal customClass={"h-svh"}>
                <SiteContainer customClass={"h-svh flex justify-center items-center"}>

                    {/* Content */}
                    <div className="inline-flex relative flex-col flex-nowrap justify-start items-start border-gray-200 bg-gray-100 p-6 border border-solid rounded-lg overflow-hidden">
                        <div ref={followerTextRef} className="magnetic-tooltip-gsap__title font-medium text-base tracking-[-0.02em]">Backed by same investors as</div>

                        {/* Title */}
                        <div ref={followerPopupRef} className="z-10 absolute opacity-0 px-3 magnetic-tooltip-gsap__popup p-2 rounded-lg font-semibold text-xs tracking-[-0.02em] whitespace-nowrap invisible">Paypal</div>

                        {/* List */}
                        <div ref={followerListRef}  className="relative flex flex-row flex-wrap justify-start items-start gap-3 mt-4 magnetic-tooltip-gsap__list">

                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className="magnetic-tooltip-gsap__item rounded-md w-8 h-8 cursor-pointer overflow-hidden"
                                    magnetic-tooltip-gsap-color={item.color}
                                    magnetic-tooltip-gsap-name={item.name}
                                    ref={el => (followerItemsRefs.current[index] = el)}
                                >
                                    <img
                                        src={item.imgSrc}
                                        alt={item.name}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            ))}

                        </div>

                    </div>
                    {/* End content */}

                </SiteContainer>
            </PaddingGlobal>

        </div>
    )
}

export default MagneticTooltipGsap
