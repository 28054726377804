import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SidebarOverlay } from './SidebarOverlay';
import { sidebarVariants } from '../../utils/animations';

const Sidebar = ({ isOpen, toggle }) => {


    return (
        <React.Fragment>
            <div
                className="inline-flex justify-center items-center border-gray-300 max-991:hidden px-8 border-l h-full cursor-pointer"
                onClick={toggle}
            >
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-flex group-hover:opacity-85 text-black transition-opacity duration-200"
                >
                    <rect x="1.25" y="8.25" width="24" height="1.3" className="fill-current"></rect>
                    <rect x="9.25" y="16.25" width="16" height="1.3" className="fill-current"></rect>
                </svg>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            className="top-0 right-0 z-[120] fixed bg-gray-900 px-20 pt-36 pb-8 w-[460px] h-full min-h-full text-white overflow-hidden"
                            variants={sidebarVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            {/* Close Button */}
                            <span
                                className="block top-10 right-10 z-[1000] absolute h-auto transition-[color] duration-[0.2s] cursor-pointer ease-[ease-out]"
                                onClick={toggle}
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width="32"
                                    height="32"
                                    stroke="currentColor"
                                    strokeWidth="1"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="fill-white"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </span>

                            {/* Sidebar Content */}
                            <div className="w-full">
                                <h2 className="mb-6 font-bold text-2xl">Sidebar Content</h2>
                                <p className="text-gray-300">Add your content here...</p>
                            </div>
                        </motion.div>

                        <SidebarOverlay isOpen={isOpen} toggle={toggle} />
                    </>
                )}
            </AnimatePresence>
        </React.Fragment>
    );
};

export default Sidebar;
