import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Shared components
import Icons from '../Icons'
import PaddingGlobal from '../../layout/PaddingGlobal'
import SiteContainer from "../../layout/Container"

const NextPrevModalLightbox = () => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const cards = [
        { imgSrc: './images/1440x960-image-one.webp', title: 'Lorem ipsum 1' },
        { imgSrc: './images/1440x960-image-two.webp', title: 'Lorem ipsum 2' },
        { imgSrc: './images/1440x960-image-three.webp', title: 'Lorem ipsum 3' },
        { imgSrc: './images/1440x960-image-four.webp', title: 'Lorem ipsum 4' },
    ];

    const openLightbox = (index) => {
        setActiveIndex(index);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
    };

    const next = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    };

    const prev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    };


    return (
        <React.Fragment>
            <div className='py-[7.5rem]'>
                <PaddingGlobal>
                    <SiteContainer customClass={"max-w-5xl"}>
                        <div className="mx-auto mt-0 mb-[42px] max-w-screen-md text-[32px] text-center tracking-[-0.02em]">Open the card, then click the Next and Previous buttons on the modal</div>

                        {/* Content */}
                        <div className="relative justify-center items-stretch gap-x-6 gap-y-6 grid grid-cols-[1fr_1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto">

                            {cards.map((item, index) => (
                                <div 
                                    key={index}
                                    className="custom-lightbox__card cursor-pointer"
                                    onClick={() => openLightbox(index)}
                                >
                                    <div className="mb-2 rounded-md w-full max-h-64 overflow-hidden">
                                        <img 
                                            src={item.imgSrc} 
                                            alt={item.title} 
                                            className="w-full h-full object-center object-cover" 
                                        />
                                    </div>
                                    <div className="font-medium text-lg tracking-[-0.02em]">{item.title}</div>
                                </div>
                            ))}
                        </div>

                    </SiteContainer>
                </PaddingGlobal>
            </div>

            {/* Lightbox */}
            <AnimatePresence>
                {isLightboxOpen && (
                    <motion.div 
                        className="top-0 right-0 bottom-0 left-0 z-[1000] fixed flex justify-center items-center bg-black/90 w-full h-full text-white"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <PaddingGlobal customClass={"h-svh"}>
                            <SiteContainer customClass={"h-svh"}>

                                <div className="h-svh">
                                    <button 
                                        onClick={closeLightbox}
                                        className='top-0 right-0 bottom-auto left-auto z-40 absolute flex justify-center items-center border-gray-100 hover:opacity-60 m-[2em] border border-solid rounded-[50%] w-12 max-w-full h-12 transition-all duration-200 cursor-pointer button__close ease-[ease] outline-none'
                                    >
                                        <Icons 
                                            nameIcon='close'
                                            className="w-4 h-4"
                                        />
                                    </button>

                                    {/* Prev */}
                                    <button 
                                        onClick={prev}
                                        className="inline-flex top-2/4 right-auto left-4 z-20 absolute justify-center items-center bg-white hover:opacity-60 rounded-[50%] w-12 h-12 text-gray-900 transition-all -translate-y-2/4 duration-200 cursor-pointer button__prev ease-[ease] outline-none"
                                    >
                                        <Icons 
                                            nameIcon='chevron-left'
                                            className="w-4 h-4"
                                        />
                                    </button>

                                    {/* Next */}
                                    <button 
                                        onClick={next}
                                        className="inline-flex top-2/4 right-4 left-auto z-20 absolute justify-center items-center bg-white hover:opacity-60 rounded-[50%] w-12 h-12 text-gray-900 transition-all -translate-y-2/4 duration-200 cursor-pointer button__next ease-[ease] outline-none"
                                    >
                                        <Icons 
                                            nameIcon='chevron-right'
                                            className="w-4 h-4"
                                        />
                                    </button>

                                    {/* List Lightbox */}
                                    <div className="relative z-10 w-full">
                                        {/* Inner */}
                                        <div className="relative flex justify-center items-center mx-auto w-full max-w-screen-md h-screen">

                                            <AnimatePresence mode="wait">
                                                <motion.div
                                                    key={activeIndex}
                                                    initial={{ opacity: 0, translateY: 16 }}
                                                    animate={{ opacity: 1, translateY: 0 }}
                                                    exit={{ opacity: 0, translateY: 16 }}
                                                    transition={{ duration: 0.4 }}
                                                    className="absolute mx-auto my-0 ease-[ease] lightbox__item"
                                                >
                                                    <img 
                                                        src={cards[activeIndex].imgSrc}
                                                        alt={cards[activeIndex].title}
                                                        className='mb-6 w-full h-auto object-cover'
                                                    />

                                                    <div className="font-semibold text-[32px] tracking-[-0.02em]">
                                                        {cards[activeIndex].title}
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>
                                            
                                        </div>
                                    </div>
                                </div>
                            </SiteContainer>
                        </PaddingGlobal>
                    </motion.div>
                )}
            </AnimatePresence>
        </React.Fragment>
    )
}

export default NextPrevModalLightbox
