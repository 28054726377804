export const submenuVariants = {
    open: { opacity: 1, y: 0, display: 'block' },
    closed: { opacity: 0, y: -10, transitionEnd: { display: 'none' } },
};

export const sidebarVariants = {
    initial: {
        opacity: 0,
        x: 460  // Starts from right side
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.6,
            ease: [0.77, 0, 0.175, 1]
        }
    },
    exit: {
        opacity: 0,
        x: 460,
        transition: {
            duration: 0.6,
            ease: [0.77, 0, 0.175, 1]
        }
    }
};

export const overlayVariants = {
    initial: {
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        cursor: 'none'
    },
    animate: (isOpen) => ({
        opacity: isOpen ? 0.45 : 0,
        visibility: isOpen ? 'visible' : 'hidden',
        pointerEvents: isOpen ? 'auto' : 'none',
        cursor: isOpen ? 'pointer' : 'none'
    }),
    transition: { duration: 0.15, ease: [.25, .46, .45, .94] },
};

export const cartDropdownVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
    transition: { duration: 0.2, ease: "easeInOut" }
};


export const mobileSubmenuVariants = {
    initial: { height: 0, opacity: 0 },
    animate: (isActive) => ({
        height: isActive ? "auto" : 0,
        opacity: isActive ? 1 : 0,
        transition: { duration: 0.3, ease: "easeInOut" }
    }),
    exit: { height: 0, opacity: 0 }
};

export const mobileNavVariants = {
    initial: { x: -320, visibility: "hidden" },
    animate: (isOpen) => ({
        x: isOpen ? 0 : -320,
        visibility: isOpen ? "visible" : "hidden",
        transition: {
            duration: 0.6,
            ease: [0.77, 0, 0.175, 1],
        }
    }),
    exit: {
        x: -320,
        visibility: "hidden",
        transition: {
            duration: 0.6,
            ease: [0.77, 0, 0.175, 1],
        }
    }
};

export const hamburgerVariants = {
    line1: {
        initial: { rotate: 0 },
        animate: (isOpen) => ({
            rotate: isOpen ? 45 : 0,
            transition: { duration: 0.3 }
        })
    },
    line2: {
        initial: { opacity: 0 },
        animate: (isOpen) => ({
            rotate: isOpen ? 90 : 0,
            opacity: isOpen ? 0 : 0,
            transition: { duration: 0.3 }
        })
    },
    line3: {
        initial: { translateY: 7 },
        animate: (isOpen) => ({
            rotate: isOpen ? 90 : 0,
            opacity: isOpen ? 1 : 1,
            width: isOpen ? '100%' : '16px',
            translateY: isOpen ? 0 : 7,
            transition: { duration: 0.3 }
        })
    }
};
