import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const MarqueeSplide = () => {

    return (
        <section className='py-32'>
            <div className="relative before:bg-[linear-gradient(to_right,white,rgba(255,255,255,0))] after:right-0 before:left-0 after:bg-[linear-gradient(to_left,white,rgba(255,255,255,0))] before:z-[2] after:z-[2] before:absolute after:absolute before:inset-y-0 after:inset-y-0 before:content-[''] after:content-[''] before:w-1/5 after:w-1/5 overflow-hidden">
                
                <Splide
                    options={{
                        type: 'loop',
                        drag: 'free',
                        focus: 'center',
                        gap: '1.25rem',
                        arrows: false,
                        pagination: false,
                        autoWidth: true,
                        autoScroll: {
                            speed: 0.3,
                            pauseOnHover: false,
                            pauseOnFocus: false,
                            rewind: false,
                        },
                    }}
                    extensions={{ AutoScroll }}
                    >
                    {[...Array(7)].map((_, index) => (
                        <SplideSlide key={index} className="w-[26.25rem]">
                            <img 
                                src="https://via.placeholder.com/472x272.png" 
                                alt="" 
                                className='relative mr-32 mb-5 ml-[-8rem] rounded-md w-full h-auto object-center object-cover'    
                            />
                            <img 
                                src="https://via.placeholder.com/472x272.png" 
                                alt="" 
                                className='relative rounded-md w-full h-auto object-center object-cover'    
                            />
                        </SplideSlide>
                    ))}
                </Splide>

            </div>
        </section>
    )
}

export default MarqueeSplide
