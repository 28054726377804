// src/components/shared/HeaderOne/components/NavigationMobile/index.jsx
import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { navItems } from '../../constants/navigationData';
import { useToggle } from '../../hooks/useToggle';
import { useSubmenu } from '../../hooks/useSubmenu';
import { MobileOverlay } from './MobileOverlay';
import { MobileSubmenu } from './MobileSubmenu';
import Icons from '../../../Icons';
import { mobileNavVariants, hamburgerVariants } from '../../utils/animations';

const NavigationMobile = () => {
    const [isOpen, toggleMenu, setIsOpen] = useToggle(false);
    const [shouldRenderNav, setShouldRenderNav] = useToggle(false);
    const { activeSubmenu, toggleSubmenu } = useSubmenu();

    const handleToggleMenu = () => {
        if (isOpen) {
            toggleMenu();
            setTimeout(() => {
                setShouldRenderNav(false);
                toggleSubmenu(null);  // Reset submenu
            }, 600);
        } else {
            toggleMenu();
            setShouldRenderNav(true);
        }
    };





    return (
        <React.Fragment>
            {/* Hamburger Button */}
            <div className="flex flex-1 justify-end items-center">
                <div
                    className="relative flex px-8 py-0 h-full text-black transition-transform duration-300 cursor-pointer"
                    onClick={handleToggleMenu}
                >
                    <div className="relative flex items-center mx-0 -mt-2 mb-0 w-6 h-[11px] will-change-transform transition-transform duration-300">
                        <motion.div
                            className="relative bg-current w-full h-px"
                            variants={hamburgerVariants.line1}
                            initial="initial"
                            animate="animate"
                            custom={isOpen}
                        >
                            <motion.span
                                className="right-0 absolute bg-current w-full h-px"
                                variants={hamburgerVariants.line2}
                                initial="initial"
                                animate="animate"
                                custom={isOpen}
                            />
                            <motion.span
                                className="right-0 absolute bg-current w-4 h-px"
                                variants={hamburgerVariants.line3}
                                initial="initial"
                                animate="animate"
                                custom={isOpen}
                            />
                        </motion.div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {(isOpen || shouldRenderNav) && (
                    <>
                        {/* Navigation Menu */}
                        <motion.nav
                            className="top-0 z-[999999] fixed bg-gray-100 px-10 py-20 w-80 h-full min-h-full overflow-y-auto"
                            variants={mobileNavVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            custom={isOpen}
                        >
                            <ul className='flex flex-col flex-nowrap justify-start items-start gap-2 m-0 p-0 w-full list-none'>
                                {navItems.map((item, index) => (
                                    <li
                                        key={index}
                                        className={`pb-4 border-b border-b-[none] border-b-gray-100 border-solid w-full ${item.submenu ? 'flex flex-row flex-wrap justify-between items-center' : 'block'}`}
                                    >
                                        <a
                                            href={item.href}
                                            className={`font-semibold text-gray-600 no-underline tracking-[-0.02em] ${item.submenu ? 'inline-flex w-[initial]' : 'w-full block'}`}
                                        >
                                            {item.label}
                                        </a>

                                        {item.submenu && (
                                            <span
                                                className='relative transition-all duration-[0.1s] cursor-pointer'
                                                onClick={() => toggleSubmenu(item.label)}
                                            >
                                                <motion.span
                                                    className='inline-flex relative'
                                                    animate={{ rotate: activeSubmenu === item.label ? 180 : 0 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <Icons
                                                        nameIcon='chevron-down'
                                                        className={"w-4 h-4"}
                                                    />
                                                </motion.span>
                                            </span>
                                        )}

                                        {item.submenu && (
                                            <MobileSubmenu
                                                item={item}
                                                activeSubmenu={activeSubmenu}
                                                toggleSubmenu={toggleSubmenu}
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </motion.nav>

                        {/* Overlay */}
                        <MobileOverlay isOpen={isOpen} toggleMenu={handleToggleMenu} />
                    </>
                )}
            </AnimatePresence>
        </React.Fragment>
    );
};

export default NavigationMobile;
