import { useEffect } from 'react';

export const useEscapeKey = (handler) => {
    useEffect(() => {
        const handleEscPress = (event) => {
            if (event.key === 'Escape') {
                handler();
            }
        };

        document.addEventListener('keydown', handleEscPress);
        return () => document.removeEventListener('keydown', handleEscPress);
    }, [handler]);
};
