import React, { useState } from 'react';
import { navItems } from '../../constants/navigationData';
import DesktopSubmenu from './DesktopSubmenu';

const NavigationDesktop = () => {
    const [isSubmenuOpen, setSubmenuOpen] = useState({
        Pages: false,
        Portfolio: false,
    });

    const toggleSubmenu = (label, isOpen) => {
        setSubmenuOpen((prevState) => ({
            ...prevState,
            [label]: isOpen,
        }));
    };

    return (
        <nav className="flex-1 max-991:hidden h-full">
            <ul className="flex flex-row flex-wrap justify-end items-center gap-10 m-0 p-0 h-full list-none">
                {navItems.map((item, index) => (
                    <li
                        key={index}
                        className="relative flex flex-row flex-wrap justify-center items-center h-full group"
                        onMouseEnter={() => toggleSubmenu(item.label, true)}
                        onMouseLeave={() => toggleSubmenu(item.label, false)}
                    >
                        <a
                            href={item.href}
                            className="group-hover:after:scale-x-100 inline-flex relative after:top-0 after:left-0 after:z-[1] after:absolute justify-center items-center after:content-[''] after:bg-gray-600 px-2 after:w-full h-full after:h-0.5 font-medium text-base text-gray-600 no-underline tracking-[-0.02em] after:will-change-transform after:duration-[0.3s,-webkit-transform] after:transition-transform overflow-hidden after:scale-x-0"
                        >
                            {item.label}
                        </a>

                        {item.submenu && (
                            <DesktopSubmenu
                                submenu={item.submenu}
                                isOpen={isSubmenuOpen[item.label]}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavigationDesktop;
