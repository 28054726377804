import React from 'react';
import { motion } from 'framer-motion';
import { submenuVariants } from '../../utils/animations';


const DesktopSubmenu = ({ submenu, isOpen }) => {

    return (
        <motion.ul
            className="top-full left-0 z-[999] absolute border-gray-300 px-6 py-8 border border-t-0 border-solid w-[300px] text-left"
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            variants={submenuVariants}
        >
            {submenu.map((submenuItem, subIndex) => (
                <li key={subIndex} className="flex-start mb-3 w-full group/child">
                    <a
                        href={submenuItem.href}
                        className="group-hover/child:before:scale-100 inline-flex relative before:bottom-0 before:left-0 before:absolute before:content-[''] pb-1 before:border-b-gray-300 before:border-b before:border-solid w-full before:w-full font-medium text-gray-600 text-sm no-underline tracking-[-0.02em] before:will-change-transform before:origin-[0_0] before:transition-all before:duration-[0.25s] before:ease-[cubic-bezier(.37,0.31,0.2,0.85)] before:scale-x-0 before:scale-y-100"
                    >
                        {submenuItem.label}
                    </a>
                </li>
            ))}
        </motion.ul>
    );
};

export default DesktopSubmenu;
