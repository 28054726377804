import React from 'react';
import { motion } from 'framer-motion';
import { overlayVariants } from '../../utils/animations';

export const MobileOverlay = ({ isOpen, toggleMenu }) => {
    return (
        <motion.div
            className='top-0 left-0 z-[888] fixed bg-gray-900/45 w-full h-full'
            variants={overlayVariants}
            initial="initial"
            animate="animate"
            custom={isOpen}
            onClick={toggleMenu}
        />
    );
};
