import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import PaddingGlobal from '../../layout/PaddingGlobal';
import SiteContainer from "../../layout/Container";

import './style.css';

const ShowCaseInteractiveCenter = () => {
    const [isTextListActive, setIsTextListActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    
    const showcases = [
        { number: '01', title: 'Design', subtitle: 'Everybody Sunshine', imgSrc: './images/1440x960-image-one.webp' },
        { number: '02', title: 'Branding', subtitle: 'Who Dares Wins', imgSrc: './images/1440x960-image-two.webp' },
        { number: '03', title: 'Branding', subtitle: 'Fountain Studio', imgSrc: './images/1440x960-image-three.webp' },
        { number: '04', title: 'Photography', subtitle: 'April Willow Flower', imgSrc: './images/1440x960-image-four.webp' }
    ];

    return (
        <div className="py-[120px] h-svh">
            <PaddingGlobal customClass={"h-svh"}>
                <SiteContainer customClass={"h-svh"}>

                    <div className="flex justify-center items-center mx-auto my-0 max-w-6xl min-h-[70vh]">
                        {/* List text */}
                        <ul
                            className='relative z-[4] flex max-767:flex-col justify-center max-767:justify-start items-start max-767:items-start gap-10 mx-auto mt-10 mb-0 px-0 py-4 w-full max-767:w-[295px] list-none showcase-text__list'
                            onMouseEnter={() => setIsTextListActive(true)}
                            onMouseLeave={() => {
                                setIsTextListActive(false);
                                setActiveIndex(null);
                            }}
                        >
                            {showcases.map((item, index) => (
                                <li
                                    key={index}
                                    className={`cursor-pointer showcase-text__item transition-all duration-[0.2s] ${isTextListActive ? 'opacity-60' : ''}`}
                                    onMouseEnter={() => setActiveIndex(index)}
                                >
                                    <span className="showcase-text__item-number mb-2 font-medium text-gray-600 text-xs">
                                        {item.number}
                                    </span>

                                    <div className="showcase-text__item-title mb-1 font-semibold text-[13px] uppercase tracking-[-0.02em]">{item.title}</div>
                                    
                                    <div className={`showcase-text__item-subtitle text-3xl transition-all duration-[0.4s] tracking-[-0.02em] font-extrabold ${isTextListActive ? 'is-active' : ''}`}>{item.subtitle}</div>
                                </li>
                            ))}
                        </ul>

                        {/* List image */}
                        <ul
                            className={`showcase-img__list fixed h-[70vh] w-full max-w-[80vw] -translate-x-2/4 -translate-y-2/4 pointer-events-none overflow-hidden m-0 p-0 left-2/4 top-2/4 list-none`}
                        >
                            {showcases.map((item, index) => (
                                <AnimatePresence key={index}>
                                    {activeIndex === index && (
                                        <motion.li
                                            initial={{ opacity: 0, scale: 1.2 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 1.2 }}
                                            transition={{ duration: 0.7 }}
                                            className={`showcase-img__item absolute w-full h-full object-cover left-0 top-0 flex justify-center items-center`}
                                        >
                                            <img 
                                                src={item.imgSrc} 
                                                alt={item.subtitle} 
                                                className='rounded-sm w-full h-full object-center object-cover'
                                            />
                                        </motion.li>
                                    )}
                                </AnimatePresence>
                            ))}
                        </ul>
                    </div>

                </SiteContainer>
            </PaddingGlobal>
        </div>
    );
};

export default ShowCaseInteractiveCenter;
