// src/components/shared/HeaderOne/components/NavigationMobile/MobileSubmenu.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { mobileSubmenuVariants } from '../../utils/animations';

export const MobileSubmenu = ({ item, activeSubmenu, toggleSubmenu }) => {
    const isActive = activeSubmenu === item.label;

    return (
        <AnimatePresence>
            <motion.ul
                className='p-0 w-full overflow-hidden list-none'
                variants={mobileSubmenuVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                custom={isActive}
            >
                {item.submenu.map((submenuItem, subIndex) => (
                    <li
                        key={subIndex}
                        className={`text-sm ${subIndex === 0 ? 'mt-4' : ''} ${subIndex === item.submenu.length - 1 ? 'mb-4' : ''}`}
                    >
                        <a
                            href={submenuItem.href}
                            className='block pt-2 w-full font-semibold text-gray-600 no-underline tracking-[-0.02em]'
                        >
                            {submenuItem.label}
                        </a>
                    </li>
                ))}
            </motion.ul>
        </AnimatePresence>
    );
};
