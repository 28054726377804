// components/Cart/index.jsx
import React from 'react';
import { useToggle } from '../../hooks/useToggle';
import { CartDropdown } from './CartDropdown';

const Cart = () => {
    const [isCartVisible, toggleCartVisibility] = useToggle(false);

    return (
        <div
            className="inline-flex relative justify-center items-center max-991:hidden ml-8 px-2 py-0 border-l border-l-gray-300 border-solid h-full cursor-pointer"
            onMouseEnter={() => toggleCartVisibility(true)}
            onMouseLeave={() => toggleCartVisibility(false)}
        >
            <div className="relative mx-6">
                {/* Cart Counter Badge */}
                <span className="inline-flex -top-1.5 right-0 absolute justify-center bg-red-500 rounded-[50%] w-4 h-4 font-medium text-[10px] text-white">
                    0
                </span>

                {/* Cart Icon */}
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-flex fill-gray-900"
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.07288 11.3165C7.56947 11.3165 6.4527 12.7087 6.77883 14.1763L6.14431 14.3173L6.77883 14.1763L9.00106 24.1763C9.23999 25.2515 10.1936 26.0165 11.2951 26.0165H20.7043C21.8058 26.0165 22.7594 25.2515 22.9983 24.1763L22.9983 24.1763L25.2206 14.1763C25.5467 12.7087 24.4299 11.3165 22.9265 11.3165H9.07288ZM5.50979 14.4583C5.00324 12.1789 6.73779 10.0165 9.07288 10.0165H22.9265C25.2616 10.0165 26.9961 12.1789 26.4896 14.4583L26.4896 14.4583L24.2673 24.4583C23.8963 26.1283 22.4151 27.3165 20.7043 27.3165H11.2951C9.58435 27.3165 8.10312 26.1283 7.73201 24.4583L5.50979 14.4583Z" fill="currentColor"></path>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 5.8335C14.2679 5.8335 12.8638 7.23768 12.8638 8.96986V13.7779H11.8638V8.96986C11.8638 6.68541 13.7156 4.8335 16.0001 4.8335C18.2845 4.8335 20.1365 6.6854 20.1365 8.96986V13.7779H19.1365V8.96986C19.1365 7.2377 17.7322 5.8335 16.0001 5.8335Z" fill="currentColor"></path>
                </svg>
            </div>

            <CartDropdown isVisible={isCartVisible} />
        </div>
    );
};

export default Cart;
