import React from 'react'

const Container = ({ customClass, children }) => {
    return (
        <div className={`relative mx-auto w-full max-w-[1170px] ${customClass ? customClass : ''}`}>
            {children}
        </div>
    )
}

export default Container
