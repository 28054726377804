import React from 'react'

const PaddingGlobal = ({ customClass, children }) => {
    return (
        <div className={`px-8 max-1023:px-5 w-full ${customClass ? customClass : '' }`}>
            {children}
        </div>
    )
}

export default PaddingGlobal
