import React from 'react';
import { motion } from 'framer-motion';
import { overlayVariants } from '../../utils/animations';

export const SidebarOverlay = ({ isOpen, toggle }) => {
    return (
        <motion.div
            className='top-0 left-0 z-[109] fixed bg-gray-900 w-full h-full'
            variants={overlayVariants}
            initial="initial"
            animate="animate"
            custom={isOpen}
            onClick={toggle}
        />
    );
};
