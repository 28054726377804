// src/components/shared/HeaderOne/components/Logo/index.jsx
import React from 'react';

const Logo = () => {
    return (
        <div className="inline-flex justify-center items-center px-7 border-r border-r-gray-300 border-solid h-full">
            <a href="#" className='inline-block'>
                <img
                    src="https://via.placeholder.com/66x66.png"
                    alt="Header one"
                    className='inline-flex bg-cover rounded-[50%] w-16 h-16'
                />
            </a>
        </div>
    );
};

export default Logo;
