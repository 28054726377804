import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cartDropdownVariants } from '../../utils/animations';

export const CartDropdown = ({ isVisible }) => {
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    variants={cartDropdownVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={cartDropdownVariants.transition}

                    className="top-full right-0 z-20 absolute flex flex-col flex-wrap justify-center items-center gap-4 border-gray-300 bg-[rgba(249,250,251,0.2)] px-8 py-6 border border-solid w-[300px] text-center"
                >
                    <span className="block relative w-full font-medium text-gray-500 text-sm tracking-[-0.02em]">
                        No products in the cart.
                    </span>
                    <a href="#" className="bg-blue-500 p-3 rounded-full w-full text-center text-white">
                        Continue shopping
                    </a>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
